import { Ring } from "@uiball/loaders";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ReactComponent as CheckMark,
  ReactComponent as Check,
} from "../../assets/icons/check.svg";
import { Button } from "../../components/Button";
import { CustomText } from "../../components/CustomText";
import {
  ButtonContainer,
  Container,
  Content as ExContent,
} from "../../components/styled-components";
import Colors from "../../utils/Colors";
import { DocumentItem } from "./components/DocumentItem";
import "./ContentPage.css";
import { ContentSlider } from "./ContentSlider";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { useContentDetails } from "../../hooks/useContentDetails";
import { useContentFunctions } from "../../hooks/useContentFunctions";
import {
  ContentWithDetailsDtoTypeEnum,
  MediaResponseDtoTypeEnum,
} from "../../new-api/api";
import { ISlide } from "../../../types";
import { BackButton } from "../../components/BackButton";
import { Loader } from "../../components/Loader";
import { useContentMaterialAndMedia } from "../../hooks/useContentMaterialAndMedia";

const Content = styled(ExContent)<{ isLoading: boolean }>`
  display: flex;
  justify-content: ${({ isLoading }) => (isLoading ? "center" : "flex-start")};
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 40px;
`;

const InnerContent = styled.div`
  margin-left: 36px;
  margin-right: 36px;
`;

const UpperContent = styled.div`
  padding-top: ${isMobile ? 20 : 0}px;
  padding-left: ${isMobile ? 20 : 0}px;
  padding-right: ${isMobile ? 20 : 0}px;
`;

const CheckButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  right: 20px;
  border-radius: 30px;
  background-color: ${Colors.primaryGreen};
`;

const CustomTextArea = styled.textarea`
  margin-top: 20px;
  width: calc(100% - 40px);
  height: ${isMobile ? 50 : 100}px;
  border: 1px solid ${Colors.black10};
  border-radius: 4px;
  font-family: Archivo;
  font-size: 16px;
  padding: 20px;

  &:focus {
    outline: none !important;
    border: 1px solid ${Colors.primaryGreen};
  }
`;

export const ContentPage = ({ isArchive }: { isArchive?: boolean }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: content, isLoading } = useContentDetails({
    contentId: Number(id),
  });
  const {
    markContentFinished: {
      mutateAsync: markContentFinished,
      isLoading: isMarkingContentFinished,
    },
    commentContent: {
      mutateAsync: commentContent,
      isLoading: isCommentingContent,
    },
  } = useContentFunctions({ contentId: Number(id) });
  const { data: contentMaterial } = useContentMaterialAndMedia(Number(id));
  const [currentItem, setCurrentItem] = useState<
    MediaResponseDtoTypeEnum | "TEXT" | "YOUTUBE" | null
  >(null);

  const [hasSeenAllSlides, setHasSeenAllSlides] = useState(false);
  const [numOfDocSeen, setNumOfDocSeen] = useState(0);
  const [comment, setComment] = useState("");

  const handleReadInfoContent = useCallback(async () => {
    await markContentFinished();
    navigate("/");
  }, [markContentFinished, navigate]);

  const slides = useMemo(() => {
    return contentMaterial?.material?.medias || [];
  }, [contentMaterial?.material?.medias]);

  const slidesWImageOrVideo = (slides || [])
    .filter((s) => s?.type !== MediaResponseDtoTypeEnum.DOCUMENT)
    .map((s) => ({ name: s?.name, url: s.url, type: s?.type }));

  const slideWDocs = useMemo(
    () => ({
      documents: (slides || [])
        .filter((s) => s?.type === MediaResponseDtoTypeEnum.DOCUMENT)
        .map((s) => ({ name: s?.name, url: s?.url })),
      type: MediaResponseDtoTypeEnum.DOCUMENT,
    }),
    [slides]
  );

  const slideWText = useMemo(
    () => ({ type: "TEXT", text: contentMaterial?.material?.text || "" }),
    [contentMaterial?.material?.text]
  );
  const slideWYoutubeVideo = useMemo(
    () => ({ type: "YOUTUBE", url: contentMaterial?.material?.link || "" }),
    [contentMaterial?.material?.link]
  );

  const allSlides = useMemo(() => {
    return ([...slidesWImageOrVideo, slideWYoutubeVideo]).filter(
      (slide) => {
        return !(slide?.type === "YOUTUBE" && slide?.url === "");
      }
    ) as Array<ISlide>;
  }, [slidesWImageOrVideo, slideWYoutubeVideo]);

  useEffect(() => {
    if (!currentItem) {
      const nextItem = allSlides?.[0];
      setCurrentItem(nextItem?.type);
    }
  }, [currentItem, allSlides]);

  useEffect(() => {
    if (!hasSeenAllSlides && [0, 1].includes((allSlides || []).length)) {
      setHasSeenAllSlides(true);
    }
  }, [allSlides, hasSeenAllSlides, slideWDocs]);

  const canSeeRightBottomButton = useMemo(
    () =>
      !content?.contentInteractions?.[0]?.finishedAt &&
      currentItem !== MediaResponseDtoTypeEnum.DOCUMENT &&
      currentItem !== "TEXT" &&
      !isArchive &&
      content?.type !== ContentWithDetailsDtoTypeEnum.INFORMATION,
    [content?.contentInteractions, content?.type, currentItem, isArchive]
  );

  const innerContent = useMemo(
    () => (
      <InnerContent>
        <UpperContent>
          <CustomText fontSize={24} style={{ marginBottom: 32 }}>
            {t("attachedMaterials")}
          </CustomText>
        </UpperContent>
        {(allSlides || []).length > 0 && !isMobile && (
          <ContentSlider
            hasSeenAllSlides={hasSeenAllSlides}
            setHasSeenAllSlides={setHasSeenAllSlides}
            slides={allSlides}
          />
        )}
        {!!slideWText?.text && (
          <div style={{ marginBottom: 20 }}>
            <CustomText>{slideWText.text}</CustomText>
          </div>
        )}

        {(slideWDocs.documents || []).map((s) => (
          <DocumentItem
            key={s.name}
            document={s}
            setNumOfDocSeen={setNumOfDocSeen}
            isArchive={isArchive}
          />
        ))}
        {isArchive && (
          <>
            {(content?.comments || []).length > 0 &&
              content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION && (
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: Colors.black10,
                    paddingTop: 20,
                  }}
                >
                  <CustomText
                    fontSize={20}
                    lineHeight={1}
                    fontWeight={500}
                    color={Colors.darkGray}
                    style={{ marginBottom: 10 }}
                  >
                    {t("myComments")}
                  </CustomText>
                  {(content?.comments || []).map((c) => (
                    <div key={c.id}>
                      <CustomText>{c?.title || ""}</CustomText>
                      <CustomText color={Colors.gray}>
                        {moment(c?.createdAt).format("DD.MM.YYYY.") || ""}
                      </CustomText>
                    </div>
                  ))}
                </div>
              )}
            <ButtonContainer>
              <BackButton backTo={`/archive/${content?.id}`} />
              {![ContentWithDetailsDtoTypeEnum.INFORMATION].includes(
                content?.type
              ) && (
                <Button
                  text={t("reviewAnswers")}
                  onClick={() => navigate(`/archive/${content?.id}/questions`)}
                  buttonType="primary"
                />
              )}
            </ButtonContainer>
          </>
        )}
        {canSeeRightBottomButton && (
          <ButtonContainer>
            <BackButton backTo={`/content/${content?.id}/intro`} />
            <Button
              text={t("answerQuestions")}
              disabledHoverText={t("seeAllSlidesAndDocuments")}
              disabled={
                !(numOfDocSeen === (slideWDocs?.documents || []).length) ||
                !hasSeenAllSlides
              }
              onClick={() =>
                navigate(
                  content?.type === ContentWithDetailsDtoTypeEnum.EDUCATION
                    ? "quiz"
                    : "poll"
                )
              }
              buttonType="primary"
            />
          </ButtonContainer>
        )}
        {content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION &&
          !isArchive && (
            <>
              <CustomText
                fontSize={24}
                fontWeight={500}
                color={Colors.darkGray}
              >
                {t("sendComment")}
              </CustomText>
              <CustomText
                fontSize={16}
                fontWeight={"normal"}
                color={Colors.gray}
              >
                {t("sendCommentDescInformation")}
              </CustomText>
              <div
                style={{
                  marginBottom: isMobile ? 20 : 0,
                  paddingLeft: isMobile ? 20 : 0,
                  paddingRight: isMobile ? 20 : 0,
                }}
              >
                <CustomTextArea
                  id="w3review"
                  onChange={(event) => setComment(event.target.value)}
                  style={{ marginBottom: 20 }}
                />
              </div>
            </>
          )}

        {content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION &&
          !isMobile &&
          !isArchive && (
            <ButtonContainer>
              <BackButton />
              <Button
                text={t("sendComment")}
                buttonType="primary"
                type="submit"
                disabled={
                  !(numOfDocSeen === (slideWDocs.documents || []).length) ||
                  isCommentingContent ||
                  !hasSeenAllSlides ||
                  !comment ||
                  comment?.length === 0
                }
                loading={isCommentingContent}
                icon={Comment}
                onClick={async () => {
                  await commentContent({ comment });
                  await handleReadInfoContent();
                }}
              />
              <Button
                text={t("read")}
                icon={CheckMark}
                loading={isMarkingContentFinished}
                disabled={
                  !(numOfDocSeen === (slideWDocs.documents || []).length) ||
                  !hasSeenAllSlides
                }
                onClick={handleReadInfoContent}
                buttonType="primary"
              />
            </ButtonContainer>
          )}
        <MobileView>
          {content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION && (
            <Button
              text={t("sendComment")}
              buttonType="primary"
              type="submit"
              disabled={isCommentingContent}
              loading={isCommentingContent}
              icon={Check}
            />
          )}
          {content?.type === ContentWithDetailsDtoTypeEnum.INFORMATION &&
            !content?.contentInteractions?.[0]?.finishedAt &&
            !isArchive && (
              <CheckButtonContainer onClick={handleReadInfoContent}>
                {isMarkingContentFinished ? (
                  <Ring color={Colors.white} />
                ) : (
                  <CheckMark />
                )}
              </CheckButtonContainer>
            )}
        </MobileView>
      </InnerContent>
    ),
    [
      allSlides,
      canSeeRightBottomButton,
      comment,
      commentContent,
      content?.comments,
      content?.contentInteractions,
      content?.id,
      content?.type,
      handleReadInfoContent,
      hasSeenAllSlides,
      isArchive,
      isCommentingContent,
      isMarkingContentFinished,
      navigate,
      numOfDocSeen,
      slideWDocs.documents,
      slideWText.text,
      t,
    ]
  );

  return (
    <Container
      style={{ backgroundColor: isMobile ? Colors.white : Colors.background }}
    >
      <BrowserView renderWithFragment>
        <Content isLoading={isLoading}>
          {isLoading || !content ? <Loader /> : innerContent}
        </Content>
      </BrowserView>
      <MobileView>
        {isLoading || !content ? (
          <Ring color={Colors.primaryGreen} />
        ) : (
          innerContent
        )}
      </MobileView>
    </Container>
  );
};
