/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SignInDTO {
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string;
  /**
   * Users password
   * @minLength 8
   * @example "Password1!"
   */
  password: string;
  /**
   * User should be remembered
   * @example true
   */
  rememberMe?: boolean;
}

export interface SignUpDTO {
  /**
   * Users new password
   * @minLength 8
   * @example "Password1!"
   */
  password: string;
  /**
   * Users new password confirmation
   * @minLength 8
   * @example "Password1!"
   */
  confirmPassword: string;
}

export interface ResetPasswordDTO {
  /**
   * Users email address
   * @example "email@example.com"
   */
  email: string;
}

export interface ForgotPasswordDTO {
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string;
}

export interface HasPasswordDto {
  /**
   * Users email address or username
   * @example "email@example.com or username123"
   */
  identifier: string;
}

export interface UserGroupDTO {
  /**
   * Group id
   * @example 1
   */
  id: number;
  /**
   * Group id
   * @example 1
   */
  groupId: number;
  /**
   * Tenant client type
   * @example true
   */
  role?: object;
}

export interface CreateUserDTO {
  /**
   * Users first name
   * @example "Marcus"
   */
  firstName: string;
  /**
   * Users last name
   * @example "Aurelius"
   */
  lastName: string;
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string;
  /**
   * Users image
   * @example "/user.png"
   */
  image?: string;
  /**
   * Users phone number
   * @example "099111111"
   */
  phone?: string;
  /**
   * Users password
   * @example "password1234"
   */
  password?: string;
  /**
   * Users assigned ID
   * @example "ID-22002"
   */
  assignedId?: string;
  /**
   * User groups
   * @example [{"groupId":1,"isUser":true}]
   */
  groups?: UserGroupDTO[];
  /**
   * Users username
   * @example "username"
   */
  username?: string;
}

export interface UpdateUserDTO {
  /**
   * Users first name
   * @example "Marcus"
   */
  firstName?: string;
  /**
   * Users last name
   * @example "Aurelius"
   */
  lastName?: string;
  /**
   * Users email
   * @example "email@example.com"
   */
  email?: string;
  /**
   * Users image
   * @example "/user.png"
   */
  image?: string;
  /**
   * Users phone number
   * @example "099111111"
   */
  phone?: string;
  /**
   * Users password
   * @example "password1234"
   */
  password?: string;
  /**
   * Users assigned ID
   * @example "ID-22002"
   */
  assignedId?: string;
  /**
   * User groups
   * @example [{"groupId":1,"isUser":true}]
   */
  groups?: UserGroupDTO[];
  /**
   * Users username
   * @example "username"
   */
  username?: string;
}

export interface UpdatePasswordDTO {
  /**
   * Users old password
   * @example "password"
   */
  old: string;
  /**
   * Users new password
   * @example "password2huehue"
   */
  new: string;
}

export interface ImportUserDTO {
  /**
   * Users first name
   * @example "Marcus"
   */
  firstName: string;
  /**
   * Users last name
   * @example "Aurelius"
   */
  lastName: string;
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string;
  /**
   * Users Assigned ID
   * @example "31255431"
   */
  image?: string;
  /**
   * Users phone number
   * @example "099111111"
   */
  phone?: string;
  /**
   * Users groups
   * @example ["WhishWash"]
   */
  groups?: any[][];
  /**
   * Role of the user in the group
   * @example "CREATOR"
   */
  groupType?: object;
}

export interface ImportUsersDTO {
  /**
   * users
   * @example []
   */
  users?: ImportUserDTO[];
}

export interface GroupUserDTO {
  /**
   * Group id
   * @example 1
   */
  id: number;
  /**
   * User id
   * @example 1
   */
  userId: number;
  /**
   * Tenant client type
   * @example true
   */
  role?: object;
}

export interface UpdateGroupDTO {
  /**
   * Group name
   * @example "Thermogenics"
   */
  name?: string;
  /**
   * Group parent id
   * @example 1
   */
  parentId?: number;
  /**
   * Group parent id
   * @example 1
   */
  promotionRecipient?: boolean;
  /**
   * Group users
   * @example [{"userId":1,"isUser":true}]
   */
  users?: GroupUserDTO[];
}

export interface CreateGroupDTO {
  /**
   * Group name
   * @example "Thermogenics"
   */
  name: string;
  /**
   * Group parent id
   * @example 1
   */
  parentId?: number;
  /**
   * Group parent id
   * @example 1
   */
  promotionRecipient?: boolean;
  /**
   * Group users
   * @example [{"userId":1,"isUser":true}]
   */
  users?: GroupUserDTO[];
}

export interface ImportGroupDTO {
  /**
   * Groups name
   * @example "The round table"
   */
  name: string;
  /**
   * Group parents name
   * @example "The logistics team"
   */
  parentName?: string;
  /**
   * Is group a promotion recipient
   * @example false
   */
  promotionRecipient?: boolean;
}

export interface ImportGroupsDTO {
  /**
   * groups
   * @example []
   */
  groups?: ImportGroupDTO[];
}

export interface UpdateAdminDTO {
  /**
   * Admin first name
   * @example "Michael"
   */
  firstName?: string;
  /**
   * Admin last name
   * @example "Schumacher"
   */
  lastName?: string;
  /**
   * Admin email address
   * @example "test@test.hr"
   */
  email?: string;
  /**
   * Admin phone number
   * @example "+385978885743"
   */
  phone?: string;
  /**
   * Id
   * @example 1
   */
  id?: number;
}

export interface UpdateTenantDTO {
  /**
   * Tenant name
   * @example "Michael"
   */
  name?: string;
  /**
   * Tenant image
   * @example "img/path"
   */
  image?: string;
  /**
   * Tenant client type
   * @example true
   */
  isClient?: boolean;
  /**
   * Tenant client type
   * @example true
   */
  isAdvertiser?: boolean;
  /**
   * Tenant client type
   * @example true
   */
  isOfferee?: boolean;
  /**
   * Should specific group reach statistics be shared in promotion stats
   * @example true
   */
  hideReachAnalytics?: boolean;
  /**
   * Should specific group reach statistics be shared in promotion stats
   * @example true
   */
  hasAssignedId?: boolean;
  /**
   * Should rank be displayed in users profile
   * @example true
   */
  hideRankPreview?: boolean;
  /**
   * Describes if tenant is active
   * @example false
   */
  isActive?: boolean;
  /**
   * Tenant admin
   * @example {"name":"Michael shumacher","email":"test@test.hr","password":"Password1!"}
   */
  admin?: UpdateAdminDTO;
}

export interface CreateAdminDTO {
  /**
   * Admin first name
   * @example "Michael"
   */
  firstName: string;
  /**
   * Admin last name
   * @example "Schumacher"
   */
  lastName: string;
  /**
   * Admin email address
   * @example "test@test.hr"
   */
  email?: string;
  /**
   * Admin phone number
   * @example "+385978885743"
   */
  phone?: string;
}

export interface CreateTenantDTO {
  /**
   * Tenant name
   * @example "Michael"
   */
  name: string;
  /**
   * Tenant image
   * @example "img/path"
   */
  image?: string;
  /**
   * Tenant client type
   * @example true
   */
  isClient?: boolean;
  /**
   * Tenant client type
   * @example true
   */
  isAdvertiser?: boolean;
  /**
   * Tenant client type
   * @example true
   */
  isOfferee?: boolean;
  /**
   * Should specific group reach statistics be shared in promotion stats
   * @example true
   */
  hideReachAnalytics?: boolean;
  /**
   * Should specific group reach statistics be shared in promotion stats
   * @example true
   */
  hasAssignedId?: boolean;
  /**
   * Should rank be displayed in users profile
   * @example true
   */
  hideRankPreview?: boolean;
  /**
   * Tenant admin
   * @example {"name":"Michael shumacher","email":"test@test.hr","password":"Password1!"}
   */
  admin: CreateAdminDTO;
}

export interface CreateMediaDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Media fieldname
   * @example "images/content/1/cover.png"
   */
  fieldname: string;
  /**
   * Media originalname
   * @example "images/content/1/cover.png"
   */
  originalname: string;
  /**
   * Media encoding
   * @example "images/content/1/cover.png"
   */
  encoding: string;
  /**
   * Media mimetype
   * @example "images/content/1/cover.png"
   */
  mimetype: string;
  /**
   * Media destination
   * @example "images/content/1/cover.png"
   */
  destination?: string;
  /**
   * Media filename
   * @example "images/content/1/cover.png"
   */
  filename: string;
  /**
   * Media path
   * @example "images/content/1/cover.png"
   */
  path?: string;
  /**
   * Media size
   * @example 24000
   */
  size?: number;
  /**
   * Media width
   * @example 120
   */
  width: number;
  /**
   * Media height
   * @example 400
   */
  height: number;
}

export interface CreateMaterialDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Materials text
   * @example "Hello in this youtube tutorial we are going to learn about null pointer deference's"
   */
  text?: string;
  /**
   * Materials youtube url
   * @example "https://www.youtube.com/watch?v=IxJotei5eY4"
   */
  link?: string;
  /**
   * Material media
   * @example [{"url":"files/content/1/cover.png","type":"images/png"}]
   */
  medias?: CreateMediaDTO[];
}

export interface CreateAnswerDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Answer title
   * @example "It do be jigglin' tho."
   */
  title: string;
  /**
   * Answer correctedness
   * @example true
   */
  isCorrect?: boolean;
}

export interface CreateQuestionDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Question title
   * @example "Do it jiggle?"
   */
  title: string;
  /**
   * Content type
   * @example "SINGLE"
   */
  type: CreateQuestionDtoTypeEnum;
  /**
   * Question Answers
   * @example [{"title":"It do be jigglin' tho","isCorrect":true},{"title":"It do be not","isCorrect":false},{"title":"Not any of yis yee yee ass bussiness"}]
   */
  answers?: CreateAnswerDTO[];
}

export interface CreateSettingsDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Percentage of answers is passing
   * @example 70
   */
  passingScore?: number;
  /**
   * Are comments allowed
   * @example false
   */
  comments?: boolean;
  /**
   * Is the questions order arbitrary
   * @example false
   */
  arbitraryOrder?: boolean;
  /**
   * Should correct answers be displayed
   * @example true
   */
  showCorrect?: boolean;
  /**
   * Time restriction on answering questions in seconds
   * @example 30
   */
  timeRestriction?: number;
  /**
   * Time of end
   * @example "05-05-2024 11:30"
   */
  endsAt?: string;
  /**
   * How much answering a question is worth
   * @example 1.5
   */
  pointCoefficient?: number;
}

export interface CreateTaskDTO {
  /**
   * Id
   * @example 1
   */
  id?: number;
  /**
   * Task deadline
   * @example "04-05-2024 11:30"
   */
  deadline?: string;
  /**
   * Task Reminder in minutes
   * @example 10
   */
  reminder?: number;
  /**
   * Task Recurrence
   * @example "DAILY"
   */
  recurrence?: CreateTaskDtoRecurrenceEnum;
  /**
   * Task users
   * @example [1,2,3,5,6,123]
   */
  users: number[];
}

export interface UpdateContentDTO {
  /**
   * Content Materials
   * @example {"text":"Hello in this youtube tutorial we are going to learn about null pointer deference's","link":"https://www.youtube.com/watch?v=IxJotei5eY4","medias":[{"url":"files/content/1/cover.png","type":"images/png"}]}
   */
  material?: CreateMaterialDTO;
  /**
   * Material questions
   * @example {"title":"Do it jiggle?","type":"SINGLE","answers":[{"title":"It do be jigglin' tho","isCorrect":true},{"title":"It do be not","isCorrect":false},{"title":"Not any of yis yee yee ass business"}]}
   */
  questions?: CreateQuestionDTO[];
  /**
   * Content Settings
   * @example {"passingScore":70,"comments":false,"arbitraryOrder":false,"showCorrect":true,"timeRestriction":30,"startsAt":"04-05-2024 11:30","endsAt":"05-05-2024 11:30","pointCoefficient":1.5}
   */
  settings?: CreateSettingsDTO;
  /**
   * Content task
   * @example {"startsAt":"04-05-2024 11:30","publishAt":"04-05-2024 11:30","reminder":1,"recurrence":"DAILY"}
   */
  task?: CreateTaskDTO;
  /**
   * Content groups
   * @example [1,2,3,5,6,123]
   */
  groups?: number[];
  /**
   * Material photo
   * @example {"url":"files/content/1/cover.png","type":"images/png"}
   */
  photo?: CreateMediaDTO;
  /**
   * Content title
   * @example "Meditations"
   */
  name?: string;
  /**
   * Content description
   * @example "It is what it is"
   */
  description?: string;
  /**
   * Task publication timestamp
   * @example "2025-02-13T16:21:40.666Z"
   */
  publishAt?: string;
  /**
   * Content in progress status
   * @example "2025-02-13T16:21:40.666Z"
   */
  inProgress?: boolean;
  /**
   * Promotion is approved
   * @example "2025-02-13T16:21:40.666Z"
   */
  approved?: boolean;
  /**
   * Content category
   * @example ["WhishWash"]
   */
  category?: any[][];
  /**
   * Content image
   * @example "content/12/cover.png"
   */
  image?: string;
  /**
   * Content type
   * @example "EDUCATION"
   */
  type?: UpdateContentDtoTypeEnum;
  /**
   * Content configuration
   * @example "COMMUNICATION"
   */
  configuration?: UpdateContentDtoConfigurationEnum;
  /**
   * Id
   * @example 1
   */
  id?: number;
}

export interface CreateContentDTO {
  /**
   * Content Materials
   * @example {"text":"Hello in this youtube tutorial we are going to learn about null pointer deference's","link":"https://www.youtube.com/watch?v=IxJotei5eY4","medias":[{"url":"files/content/1/cover.png","type":"images/png"}]}
   */
  material?: CreateMaterialDTO;
  /**
   * Material questions
   * @example {"title":"Do it jiggle?","type":"SINGLE","answers":[{"title":"It do be jigglin' tho","isCorrect":true},{"title":"It do be not","isCorrect":false},{"title":"Not any of yis yee yee ass business"}]}
   */
  questions?: CreateQuestionDTO[];
  /**
   * Content Settings
   * @example {"passingScore":70,"comments":false,"arbitraryOrder":false,"showCorrect":true,"timeRestriction":30,"startsAt":"04-05-2024 11:30","endsAt":"05-05-2024 11:30","pointCoefficient":1.5}
   */
  settings?: CreateSettingsDTO;
  /**
   * Content task
   * @example {"startsAt":"04-05-2024 11:30","publishAt":"04-05-2024 11:30","reminder":1,"recurrence":"DAILY"}
   */
  task?: CreateTaskDTO;
  /**
   * Content groups
   * @example [1,2,3,5,6,123]
   */
  groups: number[];
  /**
   * Material photo
   * @example {"url":"files/content/1/cover.png","type":"images/png"}
   */
  photo?: CreateMediaDTO;
  /**
   * Content title
   * @example "Meditations"
   */
  name: string;
  /**
   * Content description
   * @example "It is what it is"
   */
  description?: string;
  /**
   * Task publication timestamp
   * @example "2025-02-13T16:21:40.666Z"
   */
  publishAt?: string;
  /**
   * Content in progress status
   * @example "2025-02-13T16:21:40.666Z"
   */
  inProgress?: boolean;
  /**
   * Promotion is approved
   * @example "2025-02-13T16:21:40.666Z"
   */
  approved?: boolean;
  /**
   * Content category
   * @example ["WhishWash"]
   */
  category?: any[][];
  /**
   * Content image
   * @example "content/12/cover.png"
   */
  image?: string;
  /**
   * Content type
   * @example "EDUCATION"
   */
  type: CreateContentDtoTypeEnum;
  /**
   * Content configuration
   * @example "COMMUNICATION"
   */
  configuration: CreateContentDtoConfigurationEnum;
}

export interface CopyContentDTO {
  /**
   * Content title
   * @example "Meditations"
   */
  name: string;
}

export interface UpdatePromotionDTO {
  /**
   * Content Settings
   * @example {"passingScore":70,"comments":false,"arbitraryOrder":false,"showCorrect":true,"timeRestriction":30,"startsAt":"04-05-2024 11:30","endsAt":"05-05-2024 11:30","pointCoefficient":1.5}
   */
  settings?: CreateSettingsDTO;
  /**
   * Content groups
   * @example [1,2,3,5,6,123]
   */
  groups?: number[];
  /**
   * Content title
   * @example "Meditations"
   */
  name?: string;
  /**
   * Content description
   * @example "It is what it is"
   */
  description?: string;
  /**
   * Task publication timestamp
   * @example "2025-02-13T16:21:40.666Z"
   */
  publishAt?: string;
  /**
   * Content in progress status
   * @example "2025-02-13T16:21:40.666Z"
   */
  inProgress?: boolean;
  /**
   * Promotion is approved
   * @example "2025-02-13T16:21:40.666Z"
   */
  approved?: boolean;
  /**
   * Id
   * @example 1
   */
  id?: number;
}

export type CreateNotificationReceiptDto = object;

export interface HttpResponse {
  code: number;
  isSuccessful: boolean;
}

export interface TaskDto {
  /** @format date-time */
  deadline: string;
  reminder: number;
  recurrence: TaskDtoRecurrenceEnum;
}

export interface ContentDto {
  id: number;
  name: string;
  description: string;
  type: ContentDtoTypeEnum;
  configuration: ContentDtoConfigurationEnum;
  category: string;
  image: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  archivedAt: string;
  approved: boolean;
  task: TaskDto | null;
}

export interface NewContentResponseDto {
  content: ContentDto[];
  count: number;
}

export interface AnswerDto {
  id: number;
  title: string;
  isCorrect: boolean;
  order: number;
}

export interface QuestionDto {
  id: number;
  type: QuestionDtoTypeEnum;
  title: string;
  order: number;
  answers: AnswerDto[];
}

export interface UserAnswerDto {
  id: number;
  text: string;
  question: QuestionDto;
  answers: AnswerDto[];
}

export interface UserAttemptDto {
  id: number;
  finalMark: number | null;
  userAnswers: UserAnswerDto[];
  /** @format date-time */
  createdAt: string;
}

export interface NewContentWithAttempts {
  id: number;
  name: string;
  description: string;
  type: NewContentWithAttemptsTypeEnum;
  configuration: NewContentWithAttemptsConfigurationEnum;
  category: string;
  image: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  archivedAt: string;
  approved: boolean;
  task: TaskDto | null;
  userAttempts: UserAttemptDto[];
}

export interface NewContentWithAttemptsResponseDto {
  content: NewContentWithAttempts[];
}

export interface ContentInteractionDto {
  id: number;
  /** @format date-time */
  finishedAt: string;
  /** @format date-time */
  createdAt: string;
}

export interface FinishedContentDto {
  id: number;
  name: string;
  description: string;
  type: FinishedContentDtoTypeEnum;
  configuration: FinishedContentDtoConfigurationEnum;
  category: string;
  image: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  archivedAt: string;
  approved: boolean;
  task: TaskDto | null;
  contentInteractions: ContentInteractionDto[];
}

export interface FinishedContentResponseDto {
  content: FinishedContentDto[];
  count: number;
}

export interface FilterValueDto {
  label: string;
  value: string;
}

export interface FinishedContentFiltersResponseDto {
  contentTypes: FilterValueDto[];
  creators: FilterValueDto[];
  categories: FilterValueDto[];
  periods: FilterValueDto[];
}

export interface SettingsResponseDto {
  id: number;
  passingScore: number;
  comments: boolean;
  arbitraryOrder: boolean;
  showCorrect: boolean;
  timeRestriction: number;
  startsAt: string;
  endsAt: string;
  pointCoefficient: number;
}

export interface CommentDto {
  id: number;
  title: string;
  /** @format date-time */
  createdAt: string;
}

export interface GroupUserDto {
  id: number;
  role: GroupUserDtoRoleEnum;
}

export interface UserDataDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  phone: string;
  role: UserDataDtoRoleEnum;
  isActive: boolean;
  expoToken: string;
  name: string;
  acceptedTerms: boolean;
  username: string;
  groups: GroupUserDto[] | null;
}

export interface ContentWithDetailsDto {
  id: number;
  name: string;
  description: string;
  type: ContentWithDetailsDtoTypeEnum;
  configuration: ContentWithDetailsDtoConfigurationEnum;
  category: string;
  image: string;
  /** @format date-time */
  publishAt: string;
  /** @format date-time */
  archivedAt: string;
  approved: boolean;
  settings: SettingsResponseDto;
  comments: CommentDto[];
  creator: UserDataDto;
  task: CreateTaskDTO | null;
  contentInteractions: ContentInteractionDto[];
}

export interface MediaResponseDto {
  id: number;
  name: string;
  url: string;
  type: MediaResponseDtoTypeEnum;
  extension: string;
  mime: string;
  width: number;
  height: number;
  orientation: string;
  color: string;
}

export interface MaterialResponseDto {
  id: number;
  text: string;
  link: string;
  medias: MediaResponseDto[];
}

export interface MaterialWithMediaResponseDto {
  material: MaterialResponseDto;
}

export interface QuestionsWithAnswersResponseDto {
  questions: QuestionDto[];
}

export interface ContentStatsResponseDto {
  finishedContent: number;
  totalContent: number;
}

export interface FinishedContentCountDto {
  type: FinishedContentCountDtoTypeEnum;
  count: number;
}

export interface FinishedContentCountResponseDto {
  count: FinishedContentCountDto[];
}

export interface UserAttemptsResponseDto {
  attempts: UserAttemptDto[];
}

export interface MarkContentViewedDTO {
  /**
   * Content Id
   * @example 4
   */
  contentId: number;
}

export interface CommentContentRequestDto {
  /**
   * Content Id
   * @example 4
   */
  contentId: number;
  /**
   * Comment message
   * @example "This is a great education"
   */
  comment: string;
}

export interface UserAnswerDTO {
  /**
   * userAnswer text
   * @example "I think therefore i come"
   */
  text?: string;
  /**
   * Answers the user picked if presented
   * @example [12,3,5,6]
   */
  answers?: number[];
  /**
   * Id of question
   * @example 5
   */
  questionId: number;
}

export interface CreateUserAnswerDTO {
  /**
   * Material questions
   * @example [{"text":"hello","questionId":1},{"questionId":2,"answers":[1,2]}]
   */
  questionAnswers?: UserAnswerDTO[];
}

export interface MediaInfoDto {
  id: number;
  name: string;
  url: string;
  type: MediaInfoDtoTypeEnum;
  extension: string;
  mime: string;
  width: number;
  height: number;
  orientation: string;
  color: string;
}

export interface MediaInfoResponseDto {
  info: MediaInfoDto;
}

export interface UpdateEmailDTO {
  /**
   * Users old email address
   * @example "email@example.com"
   */
  old: string;
  /**
   * Users new email address
   * @example "emailic@example.com"
   */
  new: string;
}

export interface UpdateNameDTO {
  /**
   * Users first name
   * @example "Marko"
   */
  firstName: string;
  /**
   * Users last name
   * @example "Poljouuuuu"
   */
  lastName: string;
}

export interface UpdatePhoneDTO {
  /**
   * Users new phone number, set to 'NULL' to remove it
   * @example "099926352"
   */
  phone: string;
}

export interface UpdatePushTokenDTO {
  /**
   * Users new push token, set to 'NULL' to remove it
   * @example "ExponentPushToken[xxxxxxxxxxxxxxxxxxxxxx]"
   */
  ept: string;
}

export interface TenantResponseDto {
  id: number;
  name: string;
  isClient: boolean;
  isAdvertiser: boolean;
  image: string;
  isOfferee: boolean;
  isActive: boolean;
}

export interface UserDataResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  phone: string;
  role: UserDataResponseDtoRoleEnum;
  isActive: boolean;
  expoToken: string;
  name: string;
  acceptedTerms: boolean;
  username: string;
  tenant: TenantResponseDto | null;
  groups: GroupUserDto[] | null;
}

export interface GroupUserWithUserDto {
  id: number;
  role: GroupUserWithUserDtoRoleEnum;
  user: UserDataDto;
}

export interface UserGroupDto {
  id: number;
  name: string;
  users: GroupUserWithUserDto[];
  parent: UserGroupDto;
  children: UserGroupDto[];
}

export interface UserGroupsResponseDto {
  groups: UserGroupDto[];
}

export interface LoginResponseDto {
  token: string;
  userId: number;
}

export interface LoginDTO {
  /**
   * Users email address
   * @example "email@example.com"
   */
  email: string;
  /**
   * Users password
   * @example "Password1!"
   */
  password: string;
  /**
   * User should be remembered
   * @example true
   */
  rememberMe?: boolean;
  /**
   * Application type
   * @example "web"
   */
  application: LoginDtoApplicationEnum;
}

export interface ErrorResponse {
  /**
   * statusCode
   * @default 401
   */
  statusCode: number;
  /** status */
  message: string;
  /** timestamp */
  timestamp: string;
  /** timestamp */
  path: string;
  /** timestamp */
  method: string;
}

export interface HasPasswordResponseDto {
  hasPassword: boolean;
}

export interface AcceptedTermsResponseDto {
  accepted: boolean;
}

export interface ResetPasswordResponseDto {
  success: boolean;
}

export interface TestPushNotificationDTO {
  /**
   * Message, should be 1 to 20 characters long
   * @example "Hello there"
   */
  message: string;
}

export interface StatsResponseDto {
  rank: number;
  accuracy: number;
  points: number;
}

export interface ImportChangesUserDTO {
  /**
   * Users first name
   * @example "Marcus"
   */
  firstName: string;
  /**
   * Users last name
   * @example "Aurelius"
   */
  lastName: string;
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string;
  /**
   * Users phone number
   * @example "099111111"
   */
  username?: string;
  /**
   * Users phone number
   * @example "099111111"
   */
  assignedId?: string;
  /**
   * Users groups
   * @example ["WhishWash"]
   */
  groups?: any[][];
  /**
   * Users phone number
   * @example "099111111"
   */
  phone?: string;
}

export interface UserImportChangesDto {
  toBeDeleted: ImportChangesUserDTO[][];
  toBeAdded: ImportChangesUserDTO[][];
  toBeUpdated: ImportChangesUserDTO[][];
  all: ImportChangesUserDTO[][];
}

/**
 * Content type
 * @example "SINGLE"
 */
export enum CreateQuestionDtoTypeEnum {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  TEXT = "TEXT",
  LINEAR = "LINEAR",
}

/**
 * Task Recurrence
 * @example "DAILY"
 */
export enum CreateTaskDtoRecurrenceEnum {
  NO_REPEAT = "NO_REPEAT",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

/**
 * Content type
 * @example "EDUCATION"
 */
export enum UpdateContentDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

/**
 * Content configuration
 * @example "COMMUNICATION"
 */
export enum UpdateContentDtoConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

/**
 * Content type
 * @example "EDUCATION"
 */
export enum CreateContentDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

/**
 * Content configuration
 * @example "COMMUNICATION"
 */
export enum CreateContentDtoConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

export enum TaskDtoRecurrenceEnum {
  NO_REPEAT = "NO_REPEAT",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum ContentDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

export enum ContentDtoConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

export enum QuestionDtoTypeEnum {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
  TEXT = "TEXT",
  LINEAR = "LINEAR",
}

export enum NewContentWithAttemptsTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

export enum NewContentWithAttemptsConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

export enum FinishedContentDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

export enum FinishedContentDtoConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

export enum GroupUserDtoRoleEnum {
  ADMIN = "ADMIN",
  CREATOR = "CREATOR",
  ANALYST = "ANALYST",
  USER = "USER",
}

export enum UserDataDtoRoleEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}

export enum ContentWithDetailsDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

export enum ContentWithDetailsDtoConfigurationEnum {
  COMMUNICATION = "COMMUNICATION",
  PROMOTION = "PROMOTION",
}

export enum MediaResponseDtoTypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum FinishedContentCountDtoTypeEnum {
  EDUCATION = "EDUCATION",
  POLL = "POLL",
  INQUIRY = "INQUIRY",
  INFORMATION = "INFORMATION",
  TASK = "TASK",
}

export enum MediaInfoDtoTypeEnum {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum UserDataResponseDtoRoleEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}

export enum GroupUserWithUserDtoRoleEnum {
  USER = "USER",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
}

/**
 * Application type
 * @example "web"
 */
export enum LoginDtoApplicationEnum {
  Web = "web",
  Mobile = "mobile",
  Admin = "admin",
}

export type EmailControllerSendTestEmailData = any;

export interface HealthControllerCheckHealthData {
  /** @example "ok" */
  status?: string;
  /** @example {"database":{"status":"up"}} */
  info?: Record<
    string,
    {
      status?: string;
      [key: string]: any;
    }
  >;
  /** @example {} */
  error?: Record<
    string,
    {
      status?: string;
      [key: string]: any;
    }
  >;
  /** @example {"database":{"status":"up"}} */
  details?: Record<
    string,
    {
      status?: string;
      [key: string]: any;
    }
  >;
}

export type LogsControllerFindAllData = any;

export type AuthControllerSignInData = any;

export type AuthControllerSingOutData = any;

export type AuthControllerSignUpData = any;

export type AuthControllerResetPasswordData = any;

export type AuthControllerForgotPasswordData = any;

export type AuthControllerGetMeData = any;

export type AuthControllerCheckHasPasswordData = any;

export interface UserControllerGetAllForTenantParams {
  type: string;
}

export type UserControllerGetAllForTenantData = any;

export type UserControllerCreateUserData = any;

export type UserControllerGetOneByIdData = any;

export type UserControllerUpdateUserData = any;

export type UserControllerDeleteUserData = any;

export type UserControllerDeleteUsersPayload = string[];

export type UserControllerDeleteUsersData = any;

export type UserControllerUpdateUserPasswordData = any;

export type UserControllerImportUsersData = any;

export type UserInfoControllerGetUserActivityData = any;

export type UserAdminControllerCreateUserData = any;

export interface GroupControllerGetAllGroupsForUserParams {
  type: string;
}

export type GroupControllerGetAllGroupsForUserData = any;

export type GroupControllerCreateGroupData = any;

export type GroupControllerGetAllGroupsForTenantData = any;

export type GroupControllerGetGroupByIdData = any;

export type GroupControllerUpdateGroupData = any;

export type GroupControllerDeleteGroupData = any;

export type GroupControllerGetGroupUsersByIdData = any;

export type GroupControllerImportUsersData = any;

export type TenantControllerGetAllTenantsData = any;

export type TenantControllerCreateTenantData = any;

export type TenantControllerGetTopFiveTenantsData = any;

export type TenantControllerGetAllTenantsGroupsListData = any;

export type TenantControllerGetTenantByIdData = any;

export type TenantControllerUpdateTenantData = any;

export type TenantControllerDeleteTenantData = any;

export type TenantControllerActivateTenantData = any;

export type TenantControllerDeactivateTenantData = any;

export type ContentControllerGetAllContentsData = any;

export type ContentControllerCreateContentData = any;

export type ContentControllerGetContentByIdData = any;

export type ContentControllerUpdateContentData = any;

export type ContentControllerDeleteContentData = any;

export type ContentControllerGetSimpleContentByIdData = any;

export type ContentControllerCopyContentData = any;

export type ContentInfoControllerGetContentCategoriesData = any;

export type ContentInfoControllerGetContentsActivityData = any;

export interface ContentInfoControllerGetContentActivityParams {
  from: string;
  to: string;
  id: number;
}

export type ContentInfoControllerGetContentActivityData = any;

export type ContentInfoControllerGetContentInfoData = any;

export type ContentInfoControllerGetContentUsersInfoData = any;

export type ContentInfoControllerGetContentResultsInfoData = any;

export type ContentInfoControllerGetContentReachInfoData = any;

export type ContentInfoControllerGetContentGroupsReachInfoData = any;

export type ContentInfoControllerGetContentUserAttemptsInfoData = any;

export type ContentInfoControllerGetContentResultsInfoForUserData = any;

export type ContentInfoControllerGetUserAttemptsForContentData = any;

export type ContentInfoControllerGetContentCommentsData = any;

export type ContentInfoControllerFixMediaData = any;

export type ContentInfoControllerFixPointsData = any;

export type ContentActionControllerArchiveContentData = any;

export type ContentActionControllerNotifyContentData = any;

export type PromotionControllerGetAllPromotionsData = any;

export type PromotionControllerGetContentByIdData = any;

export type PromotionControllerUpdateContentData = any;

export type CommentControllerGetAllCommentsData = any;

export type CommentControllerGetCommentByIdData = any;

export type CommentControllerDeleteCommentData = any;

export type UserAnswerControllerGetAllUserAnswersData = any;

export type UserAnswerControllerGetuserAnswerByIdData = any;

export type UserAnswerControllerDeleteuserAnswerData = any;

export type FilesControllerCreateFileData = any;

export type FilesControllerCreateFilesData = any;

export type NotificationReceiptsControllerCreateData = any;

export type NotificationReceiptsControllerFindAllData = any;

export type NotificationReceiptsControllerFindOneData = any;

export type NotificationReceiptsControllerRemoveData = any;

export type NotificationReceiptsControllerFindByUserIdData = any;

export type ClientControllerGetClientLogoData = any;

export interface ContentControllerGetNewContentParams {
  limit: number;
  offset: number;
}

export type ContentControllerGetNewContentData = HttpResponse & {
  payload?: NewContentResponseDto;
};

export type ContentControllerGetNewContentWithAttemptsData = HttpResponse & {
  payload?: NewContentWithAttemptsResponseDto;
};

export interface ContentControllerGetFinishedContentParams {
  limit?: number;
  offset?: number;
  creators?: number;
  categories?: string;
  periods?: number;
  contentTypes?: string;
}

export type ContentControllerGetFinishedContentData = HttpResponse & {
  payload?: FinishedContentResponseDto;
};

export type ContentControllerGetFinishedContentFiltersData = HttpResponse & {
  payload?: FinishedContentFiltersResponseDto;
};

export type ContentControllerGetContentByIdResult = HttpResponse & {
  payload?: ContentWithDetailsDto;
};

export type ContentControllerGetContentMaterialWithMediaByIdData = HttpResponse & {
  payload?: MaterialWithMediaResponseDto;
};

export type ContentControllerGetContentQuestionsWithAnswersData = HttpResponse & {
  payload?: QuestionsWithAnswersResponseDto;
};

export type ContentControllerGetContentStatsData = HttpResponse & {
  payload?: ContentStatsResponseDto;
};

export type ContentControllerGetFinishedContentCountForUserByContentTypeData = HttpResponse & {
  payload?: FinishedContentCountResponseDto;
};

export type ContentControllerGetContentAttemptsData = HttpResponse & {
  payload?: UserAttemptsResponseDto;
};

export interface ContentControllerGetContentAttemptsForManyContentsParams {
  contentIds: string;
}

export type ContentControllerGetContentAttemptsForManyContentsData = HttpResponse & {
  payload?: UserAttemptsResponseDto;
};

export type ContentControllerMarkViewedContentData = any;

export type ContentControllerMarkFinishedContentData = any;

export type ContentControllerCommentContentData = any;

export type ContentControllerSubmitAnswersData = any;

export type ContentControllerCreateContentResult = any;

export type MediaControllerGetMediaInfoData = HttpResponse & {
  payload?: MediaInfoResponseDto;
};

export type UsersControllerChangeAvatarData = any;

export type UsersControllerUpdateEmailData = any;

export type UsersControllerUpdateNameData = any;

export type UsersControllerUpdatePasswordData = any;

export type UsersControllerUpdatePhoneData = any;

export type UsersControllerUpdatePushTokenData = any;

export type UsersControllerGetUserAvatarData = any;

export type UsersControllerGetUserDataData = HttpResponse & {
  payload?: UserDataResponseDto;
};

export type UsersControllerGetUserGroupsData = HttpResponse & {
  payload?: UserGroupsResponseDto;
};

export type UsersControllerLoginData = HttpResponse & {
  payload?: LoginResponseDto;
};

export type UsersControllerCheckHasPasswordData = HttpResponse & {
  payload?: HasPasswordResponseDto;
};

export type UsersControllerAcceptTermsData = HttpResponse & {
  payload?: AcceptedTermsResponseDto;
};

export type UsersControllerResetPasswordData = HttpResponse & {
  payload?: ResetPasswordResponseDto;
};

export type UsersControllerTestPushNotificationData = any;

export type UsersControllerGetUserStatsData = any;

export type DashBoardControllerGetPlatformOverviewData = any;

export type ExportControllerExportContentData = any;

export type ExportControllerExportUsersData = any;

export type ExportControllerExportUserData = any;

export type ExportControllerExportTemplateData = any;

export type ImportControllerCheckImportTemplateData = any;

export type ImportControllerImportTemplateData = any;

import type { AxiosRequestConfig, ResponseType } from "axios";
import { HttpClient } from "./http";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

/**
 * @title Etenim
 * @version 1.0
 * @contact
 *
 * Etenim
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  email = {
    /**
     * @description Route for sending test email
     *
     * @tags email
     * @name EmailControllerSendTestEmail
     * @request GET:/email/test
     */
    emailControllerSendTestEmail: (params: RequestParams = {}) =>
      this.request<EmailControllerSendTestEmailData, any>({
        path: `/email/test`,
        method: "GET",
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags health
     * @name HealthControllerCheckHealth
     * @request GET:/health
     */
    healthControllerCheckHealth: (params: RequestParams = {}) =>
      this.request<
        HealthControllerCheckHealthData,
        {
          /** @example "error" */
          status?: string;
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status?: string;
              [key: string]: any;
            }
          >;
        }
      >({
        path: `/health`,
        method: "GET",
        ...params,
      }),
  };
  logs = {
    /**
     * No description
     *
     * @name LogsControllerFindAll
     * @request GET:/logs
     */
    logsControllerFindAll: (params: RequestParams = {}) =>
      this.request<LogsControllerFindAllData, any>({
        path: `/logs`,
        method: "GET",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignIn
     * @request POST:/auth/signin
     */
    authControllerSignIn: (data: SignInDTO, params: RequestParams = {}) =>
      this.request<AuthControllerSignInData, void>({
        path: `/auth/signin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSingOut
     * @request GET:/auth/signout
     */
    authControllerSingOut: (params: RequestParams = {}) =>
      this.request<AuthControllerSingOutData, any>({
        path: `/auth/signout`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignUp
     * @request POST:/auth/signup/{token}
     */
    authControllerSignUp: (token: string, data: SignUpDTO, params: RequestParams = {}) =>
      this.request<AuthControllerSignUpData, void>({
        path: `/auth/signup/${token}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password/{token}
     */
    authControllerResetPassword: (token: string, data: ResetPasswordDTO, params: RequestParams = {}) =>
      this.request<AuthControllerResetPasswordData, void>({
        path: `/auth/reset-password/${token}`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerForgotPassword
     * @request POST:/auth/forgot-password
     */
    authControllerForgotPassword: (data: ForgotPasswordDTO, params: RequestParams = {}) =>
      this.request<AuthControllerForgotPasswordData, void>({
        path: `/auth/forgot-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerGetMe
     * @request GET:/auth/me
     */
    authControllerGetMe: (token: string, params: RequestParams = {}) =>
      this.request<AuthControllerGetMeData, void>({
        path: `/auth/me`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerCheckHasPassword
     * @request POST:/auth/has-password
     */
    authControllerCheckHasPassword: (data: HasPasswordDto, params: RequestParams = {}) =>
      this.request<AuthControllerCheckHasPasswordData, void>({
        path: `/auth/has-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  user = {
    /**
     * @description Public route for fetching all users
     *
     * @tags user
     * @name UserControllerGetAllForTenant
     * @request GET:/user
     */
    userControllerGetAllForTenant: (query: UserControllerGetAllForTenantParams, params: RequestParams = {}) =>
      this.request<UserControllerGetAllForTenantData, any>({
        path: `/user`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Admin route for creating a user
     *
     * @tags user
     * @name UserControllerCreateUser
     * @request POST:/user
     * @secure
     */
    userControllerCreateUser: (data: CreateUserDTO, params: RequestParams = {}) =>
      this.request<UserControllerCreateUserData, void>({
        path: `/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific users
     *
     * @tags user
     * @name UserControllerGetOneById
     * @request GET:/user/{id}
     */
    userControllerGetOneById: (id: number, params: RequestParams = {}) =>
      this.request<UserControllerGetOneByIdData, void>({
        path: `/user/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for updating a specific user
     *
     * @tags user
     * @name UserControllerUpdateUser
     * @request PATCH:/user/{id}
     * @secure
     */
    userControllerUpdateUser: (id: number, data: UpdateUserDTO, params: RequestParams = {}) =>
      this.request<UserControllerUpdateUserData, void>({
        path: `/user/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific user
     *
     * @tags user
     * @name UserControllerDeleteUser
     * @request DELETE:/user/{id}
     * @secure
     */
    userControllerDeleteUser: (id: number, params: RequestParams = {}) =>
      this.request<UserControllerDeleteUserData, void>({
        path: `/user/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Route for deleting multiple users
     *
     * @tags user
     * @name UserControllerDeleteUsers
     * @request POST:/user/delete-by-ids
     * @secure
     */
    userControllerDeleteUsers: (data: UserControllerDeleteUsersPayload, params: RequestParams = {}) =>
      this.request<UserControllerDeleteUsersData, void>({
        path: `/user/delete-by-ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for resetting user password
     *
     * @tags user
     * @name UserControllerUpdateUserPassword
     * @request POST:/user/reset-password
     * @secure
     */
    userControllerUpdateUserPassword: (data: UpdatePasswordDTO, params: RequestParams = {}) =>
      this.request<UserControllerUpdateUserPasswordData, void>({
        path: `/user/reset-password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for importing users
     *
     * @tags user
     * @name UserControllerImportUsers
     * @request POST:/user/import
     * @secure
     */
    userControllerImportUsers: (data: ImportUsersDTO, params: RequestParams = {}) =>
      this.request<UserControllerImportUsersData, void>({
        path: `/user/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific users
     *
     * @tags user
     * @name UserInfoControllerGetUserActivity
     * @request GET:/user/{id}/activity
     */
    userInfoControllerGetUserActivity: (id: number, params: RequestParams = {}) =>
      this.request<UserInfoControllerGetUserActivityData, void>({
        path: `/user/${id}/activity`,
        method: "GET",
        ...params,
      }),
  };
  admin = {
    /**
     * @description Admin route for resending welcome emails
     *
     * @tags admin-user
     * @name UserAdminControllerCreateUser
     * @request POST:/admin/user/resend-welcome-emails/{id}
     * @secure
     */
    userAdminControllerCreateUser: (id: number, params: RequestParams = {}) =>
      this.request<UserAdminControllerCreateUserData, void>({
        path: `/admin/user/resend-welcome-emails/${id}`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  group = {
    /**
     * @description Public route for fetching all groups
     *
     * @tags group
     * @name GroupControllerGetAllGroupsForUser
     * @request GET:/group
     */
    groupControllerGetAllGroupsForUser: (query: GroupControllerGetAllGroupsForUserParams, params: RequestParams = {}) =>
      this.request<GroupControllerGetAllGroupsForUserData, any>({
        path: `/group`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Route for creating a group
     *
     * @tags group
     * @name GroupControllerCreateGroup
     * @request POST:/group
     * @secure
     */
    groupControllerCreateGroup: (data: CreateGroupDTO, params: RequestParams = {}) =>
      this.request<GroupControllerCreateGroupData, void>({
        path: `/group`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching all groups
     *
     * @tags group
     * @name GroupControllerGetAllGroupsForTenant
     * @request GET:/group/tenant
     */
    groupControllerGetAllGroupsForTenant: (params: RequestParams = {}) =>
      this.request<GroupControllerGetAllGroupsForTenantData, any>({
        path: `/group/tenant`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching a specific group
     *
     * @tags group
     * @name GroupControllerGetGroupById
     * @request GET:/group/{id}
     */
    groupControllerGetGroupById: (id: number, params: RequestParams = {}) =>
      this.request<GroupControllerGetGroupByIdData, void>({
        path: `/group/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for updating a specific group
     *
     * @tags group
     * @name GroupControllerUpdateGroup
     * @request PATCH:/group/{id}
     * @secure
     */
    groupControllerUpdateGroup: (id: number, data: UpdateGroupDTO, params: RequestParams = {}) =>
      this.request<GroupControllerUpdateGroupData, void>({
        path: `/group/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific group
     *
     * @tags group
     * @name GroupControllerDeleteGroup
     * @request DELETE:/group/{id}
     * @secure
     */
    groupControllerDeleteGroup: (id: number, params: RequestParams = {}) =>
      this.request<GroupControllerDeleteGroupData, void>({
        path: `/group/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific groups users
     *
     * @tags group
     * @name GroupControllerGetGroupUsersById
     * @request GET:/group/{id}/users
     */
    groupControllerGetGroupUsersById: (id: number, params: RequestParams = {}) =>
      this.request<GroupControllerGetGroupUsersByIdData, void>({
        path: `/group/${id}/users`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for importing users
     *
     * @tags group
     * @name GroupControllerImportUsers
     * @request POST:/group/import
     * @secure
     */
    groupControllerImportUsers: (data: ImportGroupsDTO, params: RequestParams = {}) =>
      this.request<GroupControllerImportUsersData, void>({
        path: `/group/import`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  tenant = {
    /**
     * @description Public route for fetching all tenants
     *
     * @tags tenant
     * @name TenantControllerGetAllTenants
     * @request GET:/tenant
     */
    tenantControllerGetAllTenants: (params: RequestParams = {}) =>
      this.request<TenantControllerGetAllTenantsData, any>({
        path: `/tenant`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for creating a tenant
     *
     * @tags tenant
     * @name TenantControllerCreateTenant
     * @request POST:/tenant
     * @secure
     */
    tenantControllerCreateTenant: (data: CreateTenantDTO, params: RequestParams = {}) =>
      this.request<TenantControllerCreateTenantData, void>({
        path: `/tenant`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching top five tenants
     *
     * @tags tenant
     * @name TenantControllerGetTopFiveTenants
     * @request GET:/tenant/top-five/{type}
     */
    tenantControllerGetTopFiveTenants: (type: string, params: RequestParams = {}) =>
      this.request<TenantControllerGetTopFiveTenantsData, any>({
        path: `/tenant/top-five/${type}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all tenants
     *
     * @tags tenant
     * @name TenantControllerGetAllTenantsGroupsList
     * @request GET:/tenant/info/groups
     */
    tenantControllerGetAllTenantsGroupsList: (params: RequestParams = {}) =>
      this.request<TenantControllerGetAllTenantsGroupsListData, any>({
        path: `/tenant/info/groups`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching a specific tenant
     *
     * @tags tenant
     * @name TenantControllerGetTenantById
     * @request GET:/tenant/{id}
     */
    tenantControllerGetTenantById: (id: number, params: RequestParams = {}) =>
      this.request<TenantControllerGetTenantByIdData, void>({
        path: `/tenant/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for updating a specific tenant
     *
     * @tags tenant
     * @name TenantControllerUpdateTenant
     * @request PATCH:/tenant/{id}
     * @secure
     */
    tenantControllerUpdateTenant: (id: number, data: UpdateTenantDTO, params: RequestParams = {}) =>
      this.request<TenantControllerUpdateTenantData, void>({
        path: `/tenant/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific tenant
     *
     * @tags tenant
     * @name TenantControllerDeleteTenant
     * @request DELETE:/tenant/{id}
     * @secure
     */
    tenantControllerDeleteTenant: (id: number, params: RequestParams = {}) =>
      this.request<TenantControllerDeleteTenantData, void>({
        path: `/tenant/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Route for activating a tenant
     *
     * @tags tenant
     * @name TenantControllerActivateTenant
     * @request POST:/tenant/{id}/activate
     * @secure
     */
    tenantControllerActivateTenant: (id: number, params: RequestParams = {}) =>
      this.request<TenantControllerActivateTenantData, void>({
        path: `/tenant/${id}/activate`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Route for deactivating a tenant
     *
     * @tags tenant
     * @name TenantControllerDeactivateTenant
     * @request POST:/tenant/{id}/deactivate
     * @secure
     */
    tenantControllerDeactivateTenant: (id: number, params: RequestParams = {}) =>
      this.request<TenantControllerDeactivateTenantData, void>({
        path: `/tenant/${id}/deactivate`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  content = {
    /**
     * @description Public route for fetching all contents
     *
     * @tags content
     * @name ContentControllerGetAllContents
     * @request GET:/content
     */
    contentControllerGetAllContents: (params: RequestParams = {}) =>
      this.request<ContentControllerGetAllContentsData, any>({
        path: `/content`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for creating a content
     *
     * @tags content
     * @name ContentControllerCreateContent
     * @request POST:/content
     * @secure
     */
    contentControllerCreateContent: (data: CreateContentDTO, params: RequestParams = {}) =>
      this.request<ContentControllerCreateContentData, void>({
        path: `/content`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific content
     *
     * @tags content
     * @name ContentControllerGetContentById
     * @request GET:/content/{id}
     */
    contentControllerGetContentById: (id: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetContentByIdData, void>({
        path: `/content/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for updating a specific content
     *
     * @tags content
     * @name ContentControllerUpdateContent
     * @request PATCH:/content/{id}
     * @secure
     */
    contentControllerUpdateContent: (id: number, data: UpdateContentDTO, params: RequestParams = {}) =>
      this.request<ContentControllerUpdateContentData, void>({
        path: `/content/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific content
     *
     * @tags content
     * @name ContentControllerDeleteContent
     * @request DELETE:/content/{id}
     * @secure
     */
    contentControllerDeleteContent: (id: number, params: RequestParams = {}) =>
      this.request<ContentControllerDeleteContentData, void>({
        path: `/content/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific content
     *
     * @tags content
     * @name ContentControllerGetSimpleContentById
     * @request GET:/content/{id}/simple
     */
    contentControllerGetSimpleContentById: (id: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetSimpleContentByIdData, void>({
        path: `/content/${id}/simple`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for creating a content
     *
     * @tags content
     * @name ContentControllerCopyContent
     * @request POST:/content/{id}/copy
     * @secure
     */
    contentControllerCopyContent: (id: number, data: CopyContentDTO, params: RequestParams = {}) =>
      this.request<ContentControllerCopyContentData, void>({
        path: `/content/${id}/copy`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching all possible content categories
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentCategories
     * @request GET:/content/info/categories
     */
    contentInfoControllerGetContentCategories: (params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentCategoriesData, void>({
        path: `/content/info/categories`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentsActivity
     * @request GET:/content/info/activity
     */
    contentInfoControllerGetContentsActivity: (params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentsActivityData, void>({
        path: `/content/info/activity`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentActivity
     * @request GET:/content/{id}/info/activity
     */
    contentInfoControllerGetContentActivity: (
      { id, ...query }: ContentInfoControllerGetContentActivityParams,
      params: RequestParams = {},
    ) =>
      this.request<ContentInfoControllerGetContentActivityData, void>({
        path: `/content/${id}/info/activity`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentInfo
     * @request GET:/content/{id}/info
     */
    contentInfoControllerGetContentInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentInfoData, void>({
        path: `/content/${id}/info`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentUsersInfo
     * @request GET:/content/{id}/users
     */
    contentInfoControllerGetContentUsersInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentUsersInfoData, void>({
        path: `/content/${id}/users`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentResultsInfo
     * @request GET:/content/{id}/results
     */
    contentInfoControllerGetContentResultsInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentResultsInfoData, void>({
        path: `/content/${id}/results`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching promotion reach
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentReachInfo
     * @request GET:/content/{id}/reach
     */
    contentInfoControllerGetContentReachInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentReachInfoData, void>({
        path: `/content/${id}/reach`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching promotion reach
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentGroupsReachInfo
     * @request GET:/content/{id}/groups-reach
     */
    contentInfoControllerGetContentGroupsReachInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentGroupsReachInfoData, void>({
        path: `/content/${id}/groups-reach`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching content user attempts
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentUserAttemptsInfo
     * @request GET:/content/{id}/attempts
     */
    contentInfoControllerGetContentUserAttemptsInfo: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentUserAttemptsInfoData, void>({
        path: `/content/${id}/attempts`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentResultsInfoForUser
     * @request GET:/content/{contentId}/users/{userId}/results/{attemptId}
     */
    contentInfoControllerGetContentResultsInfoForUser: (
      contentId: number,
      userId: number,
      attemptId: number,
      params: RequestParams = {},
    ) =>
      this.request<ContentInfoControllerGetContentResultsInfoForUserData, void>({
        path: `/content/${contentId}/users/${userId}/results/${attemptId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching all content activity by date
     *
     * @tags content-info
     * @name ContentInfoControllerGetUserAttemptsForContent
     * @request GET:/content/{contentId}/users/{userId}/attempts
     */
    contentInfoControllerGetUserAttemptsForContent: (contentId: number, userId: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetUserAttemptsForContentData, void>({
        path: `/content/${contentId}/users/${userId}/attempts`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching comments for a specific content
     *
     * @tags content-info
     * @name ContentInfoControllerGetContentComments
     * @request GET:/content/{id}/comments
     */
    contentInfoControllerGetContentComments: (id: number, params: RequestParams = {}) =>
      this.request<ContentInfoControllerGetContentCommentsData, void>({
        path: `/content/${id}/comments`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags content-info
     * @name ContentInfoControllerFixMedia
     * @request POST:/content/info/fix-media
     */
    contentInfoControllerFixMedia: (params: RequestParams = {}) =>
      this.request<ContentInfoControllerFixMediaData, any>({
        path: `/content/info/fix-media`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags content-info
     * @name ContentInfoControllerFixPoints
     * @request POST:/content/info/fix-points
     */
    contentInfoControllerFixPoints: (params: RequestParams = {}) =>
      this.request<ContentInfoControllerFixPointsData, any>({
        path: `/content/info/fix-points`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Route for archiving content
     *
     * @tags content-action
     * @name ContentActionControllerArchiveContent
     * @request POST:/content/{id}/archive
     * @secure
     */
    contentActionControllerArchiveContent: (id: number, params: RequestParams = {}) =>
      this.request<ContentActionControllerArchiveContentData, void>({
        path: `/content/${id}/archive`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Route for notifying users for content
     *
     * @tags content-action
     * @name ContentActionControllerNotifyContent
     * @request POST:/content/{id}/notify
     * @secure
     */
    contentActionControllerNotifyContent: (id: number, params: RequestParams = {}) =>
      this.request<ContentActionControllerNotifyContentData, void>({
        path: `/content/${id}/notify`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching all comments
     *
     * @tags comment
     * @name CommentControllerGetAllComments
     * @request GET:/content/{contentId}/comment
     */
    commentControllerGetAllComments: (contentId: string, params: RequestParams = {}) =>
      this.request<CommentControllerGetAllCommentsData, any>({
        path: `/content/${contentId}/comment`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching a specific comment
     *
     * @tags comment
     * @name CommentControllerGetCommentById
     * @request GET:/content/{contentId}/comment/{id}
     */
    commentControllerGetCommentById: (id: number, contentId: string, params: RequestParams = {}) =>
      this.request<CommentControllerGetCommentByIdData, void>({
        path: `/content/${contentId}/comment/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for deleting a specific comment
     *
     * @tags comment
     * @name CommentControllerDeleteComment
     * @request DELETE:/content/{contentId}/comment/{id}
     * @secure
     */
    commentControllerDeleteComment: (id: number, contentId: string, params: RequestParams = {}) =>
      this.request<CommentControllerDeleteCommentData, void>({
        path: `/content/${contentId}/comment/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  promotion = {
    /**
     * @description Private route for fetching all promotions
     *
     * @tags promotion
     * @name PromotionControllerGetAllPromotions
     * @request GET:/promotion
     */
    promotionControllerGetAllPromotions: (params: RequestParams = {}) =>
      this.request<PromotionControllerGetAllPromotionsData, any>({
        path: `/promotion`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Private route for fetching a specific content
     *
     * @tags promotion
     * @name PromotionControllerGetContentById
     * @request GET:/promotion/{id}
     */
    promotionControllerGetContentById: (id: number, params: RequestParams = {}) =>
      this.request<PromotionControllerGetContentByIdData, void>({
        path: `/promotion/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for updating a specific content
     *
     * @tags promotion
     * @name PromotionControllerUpdateContent
     * @request PATCH:/promotion/{id}
     */
    promotionControllerUpdateContent: (id: number, data: UpdatePromotionDTO, params: RequestParams = {}) =>
      this.request<PromotionControllerUpdateContentData, void>({
        path: `/promotion/${id}`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  userAnswer = {
    /**
     * @description Public route for fetching all userAnswers
     *
     * @tags content/:contentId
     * @name UserAnswerControllerGetAllUserAnswers
     * @request GET:/userAnswer
     */
    userAnswerControllerGetAllUserAnswers: (params: RequestParams = {}) =>
      this.request<UserAnswerControllerGetAllUserAnswersData, any>({
        path: `/userAnswer`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for fetching a specific userAnswer
     *
     * @tags content/:contentId
     * @name UserAnswerControllerGetuserAnswerById
     * @request GET:/userAnswer/{id}
     */
    userAnswerControllerGetuserAnswerById: (id: number, params: RequestParams = {}) =>
      this.request<UserAnswerControllerGetuserAnswerByIdData, void>({
        path: `/userAnswer/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Route for deleting a specific userAnswer
     *
     * @tags content/:contentId
     * @name UserAnswerControllerDeleteuserAnswer
     * @request DELETE:/userAnswer/{id}
     * @secure
     */
    userAnswerControllerDeleteuserAnswer: (id: number, params: RequestParams = {}) =>
      this.request<UserAnswerControllerDeleteuserAnswerData, void>({
        path: `/userAnswer/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),
  };
  files = {
    /**
     * @description Route for uploading files
     *
     * @tags files
     * @name FilesControllerCreateFile
     * @request POST:/files
     */
    filesControllerCreateFile: (params: RequestParams = {}) =>
      this.request<FilesControllerCreateFileData, void>({
        path: `/files`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Route for uploading files
     *
     * @tags files
     * @name FilesControllerCreateFiles
     * @request POST:/files/multiple
     */
    filesControllerCreateFiles: (params: RequestParams = {}) =>
      this.request<FilesControllerCreateFilesData, void>({
        path: `/files/multiple`,
        method: "POST",
        ...params,
      }),
  };
  notificationReceipts = {
    /**
     * No description
     *
     * @name NotificationReceiptsControllerCreate
     * @request POST:/notification-receipts
     */
    notificationReceiptsControllerCreate: (data: CreateNotificationReceiptDto, params: RequestParams = {}) =>
      this.request<NotificationReceiptsControllerCreateData, any>({
        path: `/notification-receipts`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationReceiptsControllerFindAll
     * @request GET:/notification-receipts
     */
    notificationReceiptsControllerFindAll: (params: RequestParams = {}) =>
      this.request<NotificationReceiptsControllerFindAllData, any>({
        path: `/notification-receipts`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationReceiptsControllerFindOne
     * @request GET:/notification-receipts/{id}
     */
    notificationReceiptsControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<NotificationReceiptsControllerFindOneData, any>({
        path: `/notification-receipts/${id}`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationReceiptsControllerRemove
     * @request DELETE:/notification-receipts/{id}
     */
    notificationReceiptsControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<NotificationReceiptsControllerRemoveData, any>({
        path: `/notification-receipts/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @name NotificationReceiptsControllerFindByUserId
     * @request GET:/notification-receipts/user/{userId}
     */
    notificationReceiptsControllerFindByUserId: (userId: string, params: RequestParams = {}) =>
      this.request<NotificationReceiptsControllerFindByUserIdData, any>({
        path: `/notification-receipts/user/${userId}`,
        method: "GET",
        ...params,
      }),
  };
  mobile = {
    /**
     * @description Route for fetching client logo
     *
     * @tags mobile-client
     * @name ClientControllerGetClientLogo
     * @request GET:/mobile/client/logo/{clientId}
     */
    clientControllerGetClientLogo: (clientId: number, params: RequestParams = {}) =>
      this.request<ClientControllerGetClientLogoData, void>({
        path: `/mobile/client/logo/${clientId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get new content for current user
     *
     * @tags mobile-content
     * @name ContentControllerGetNewContent
     * @request GET:/mobile/content/list/new
     */
    contentControllerGetNewContent: (query: ContentControllerGetNewContentParams, params: RequestParams = {}) =>
      this.request<ContentControllerGetNewContentData, void>({
        path: `/mobile/content/list/new`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Get new content for current user with user attempts
     *
     * @tags mobile-content
     * @name ContentControllerGetNewContentWithAttempts
     * @request GET:/mobile/content/list/new-with-attempts
     */
    contentControllerGetNewContentWithAttempts: (params: RequestParams = {}) =>
      this.request<ContentControllerGetNewContentWithAttemptsData, void>({
        path: `/mobile/content/list/new-with-attempts`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get finished content for current user
     *
     * @tags mobile-content
     * @name ContentControllerGetFinishedContent
     * @request GET:/mobile/content/list/finished
     */
    contentControllerGetFinishedContent: (
      query: ContentControllerGetFinishedContentParams,
      params: RequestParams = {},
    ) =>
      this.request<ContentControllerGetFinishedContentData, void>({
        path: `/mobile/content/list/finished`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Get filters for finished content of current users archived content
     *
     * @tags mobile-content
     * @name ContentControllerGetFinishedContentFilters
     * @request GET:/mobile/content/list/filters
     */
    contentControllerGetFinishedContentFilters: (params: RequestParams = {}) =>
      this.request<ContentControllerGetFinishedContentFiltersData, void>({
        path: `/mobile/content/list/filters`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get content with slides
     *
     * @tags mobile-content
     * @name ContentControllerGetContentById
     * @request GET:/mobile/content/{contentId}
     */
    contentControllerGetContentById: (contentId: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetContentByIdResult, void>({
        path: `/mobile/content/${contentId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get content materials
     *
     * @tags mobile-content
     * @name ContentControllerGetContentMaterialWithMediaById
     * @request GET:/mobile/content/{contentId}/material
     */
    contentControllerGetContentMaterialWithMediaById: (contentId: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetContentMaterialWithMediaByIdData, void>({
        path: `/mobile/content/${contentId}/material`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get content questions
     *
     * @tags mobile-content
     * @name ContentControllerGetContentQuestionsWithAnswers
     * @request GET:/mobile/content/{contentId}/questions
     */
    contentControllerGetContentQuestionsWithAnswers: (contentId: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetContentQuestionsWithAnswersData, void>({
        path: `/mobile/content/${contentId}/questions`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get content stats for query user
     *
     * @tags mobile-content
     * @name ContentControllerGetContentStats
     * @request GET:/mobile/content/info/stats
     */
    contentControllerGetContentStats: (params: RequestParams = {}) =>
      this.request<ContentControllerGetContentStatsData, void>({
        path: `/mobile/content/info/stats`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get finished content count
     *
     * @tags mobile-content
     * @name ContentControllerGetFinishedContentCountForUserByContentType
     * @request GET:/mobile/content/info/count
     */
    contentControllerGetFinishedContentCountForUserByContentType: (params: RequestParams = {}) =>
      this.request<ContentControllerGetFinishedContentCountForUserByContentTypeData, void>({
        path: `/mobile/content/info/count`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get content attempts
     *
     * @tags mobile-content
     * @name ContentControllerGetContentAttempts
     * @request GET:/mobile/content/{contentId}/form/attempts
     */
    contentControllerGetContentAttempts: (contentId: number, params: RequestParams = {}) =>
      this.request<ContentControllerGetContentAttemptsData, void>({
        path: `/mobile/content/${contentId}/form/attempts`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get multiple contents attempts
     *
     * @tags mobile-content
     * @name ContentControllerGetContentAttemptsForManyContents
     * @request GET:/mobile/content/form/attempts
     */
    contentControllerGetContentAttemptsForManyContents: (
      { contentIds, ...query }: ContentControllerGetContentAttemptsForManyContentsParams,
      params: RequestParams = {},
    ) =>
      this.request<ContentControllerGetContentAttemptsForManyContentsData, void>({
        path: `/mobile/content/form/attempts`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * @description Route for marking a content viewed by user
     *
     * @tags mobile-content
     * @name ContentControllerMarkViewedContent
     * @request POST:/mobile/content/mark-viewed
     */
    contentControllerMarkViewedContent: (data: MarkContentViewedDTO, params: RequestParams = {}) =>
      this.request<ContentControllerMarkViewedContentData, void>({
        path: `/mobile/content/mark-viewed`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for marking a content finished by user
     *
     * @tags mobile-content
     * @name ContentControllerMarkFinishedContent
     * @request POST:/mobile/content/mark-finished
     */
    contentControllerMarkFinishedContent: (data: MarkContentViewedDTO, params: RequestParams = {}) =>
      this.request<ContentControllerMarkFinishedContentData, void>({
        path: `/mobile/content/mark-finished`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for commenting content
     *
     * @tags mobile-content
     * @name ContentControllerCommentContent
     * @request POST:/mobile/content/info/comment
     */
    contentControllerCommentContent: (data: CommentContentRequestDto, params: RequestParams = {}) =>
      this.request<ContentControllerCommentContentData, void>({
        path: `/mobile/content/info/comment`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for submitting user answers
     *
     * @tags mobile-content
     * @name ContentControllerSubmitAnswers
     * @request POST:/mobile/content/{contentId}/form/attempt
     */
    contentControllerSubmitAnswers: (contentId: number, data: CreateUserAnswerDTO, params: RequestParams = {}) =>
      this.request<ContentControllerSubmitAnswersData, void>({
        path: `/mobile/content/${contentId}/form/attempt`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for creating a content
     *
     * @tags mobile-content
     * @name ContentControllerCreateContent
     * @request POST:/mobile/content
     */
    contentControllerCreateContent: (data: CreateContentDTO, params: RequestParams = {}) =>
      this.request<ContentControllerCreateContentResult, void>({
        path: `/mobile/content`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get media info
     *
     * @tags mobile-media
     * @name MediaControllerGetMediaInfo
     * @request GET:/mobile/media/info/{name}
     */
    mediaControllerGetMediaInfo: (name: string, params: RequestParams = {}) =>
      this.request<MediaControllerGetMediaInfoData, void>({
        path: `/mobile/media/info/${name}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Change users avatar
     *
     * @tags mobile-users
     * @name UsersControllerChangeAvatar
     * @request POST:/mobile/users/avatar
     * @secure
     */
    usersControllerChangeAvatar: (data: any, params: RequestParams = {}) =>
      this.request<UsersControllerChangeAvatarData, void>({
        path: `/mobile/users/avatar`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update users email
     *
     * @tags mobile-users
     * @name UsersControllerUpdateEmail
     * @request POST:/mobile/users/email
     * @secure
     */
    usersControllerUpdateEmail: (data: UpdateEmailDTO, params: RequestParams = {}) =>
      this.request<UsersControllerUpdateEmailData, void>({
        path: `/mobile/users/email`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update users name
     *
     * @tags mobile-users
     * @name UsersControllerUpdateName
     * @request POST:/mobile/users/names
     * @secure
     */
    usersControllerUpdateName: (data: UpdateNameDTO, params: RequestParams = {}) =>
      this.request<UsersControllerUpdateNameData, void>({
        path: `/mobile/users/names`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update users password
     *
     * @tags mobile-users
     * @name UsersControllerUpdatePassword
     * @request POST:/mobile/users/password
     * @secure
     */
    usersControllerUpdatePassword: (data: UpdatePasswordDTO, params: RequestParams = {}) =>
      this.request<UsersControllerUpdatePasswordData, void>({
        path: `/mobile/users/password`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update users phone
     *
     * @tags mobile-users
     * @name UsersControllerUpdatePhone
     * @request POST:/mobile/users/phone
     * @secure
     */
    usersControllerUpdatePhone: (data: UpdatePhoneDTO, params: RequestParams = {}) =>
      this.request<UsersControllerUpdatePhoneData, void>({
        path: `/mobile/users/phone`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Update users pushToken
     *
     * @tags mobile-users
     * @name UsersControllerUpdatePushToken
     * @request POST:/mobile/users/ept
     * @secure
     */
    usersControllerUpdatePushToken: (data: UpdatePushTokenDTO, params: RequestParams = {}) =>
      this.request<UsersControllerUpdatePushTokenData, void>({
        path: `/mobile/users/ept`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user avatar
     *
     * @tags mobile-users
     * @name UsersControllerGetUserAvatar
     * @request GET:/mobile/users/avatar/{rnd}
     */
    usersControllerGetUserAvatar: (rnd: any, params: RequestParams = {}) =>
      this.request<UsersControllerGetUserAvatarData, any>({
        path: `/mobile/users/avatar/${rnd}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get user data
     *
     * @tags mobile-users
     * @name UsersControllerGetUserData
     * @request GET:/mobile/users/data
     */
    usersControllerGetUserData: (params: RequestParams = {}) =>
      this.request<UsersControllerGetUserDataData, any>({
        path: `/mobile/users/data`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Get user groups
     *
     * @tags mobile-users
     * @name UsersControllerGetUserGroups
     * @request GET:/mobile/users/groups
     */
    usersControllerGetUserGroups: (params: RequestParams = {}) =>
      this.request<UsersControllerGetUserGroupsData, any>({
        path: `/mobile/users/groups`,
        method: "GET",
        ...params,
      }),

    /**
     * @description User login
     *
     * @tags mobile-users
     * @name UsersControllerLogin
     * @request POST:/mobile/users/login
     */
    usersControllerLogin: (data: LoginDTO, params: RequestParams = {}) =>
      this.request<UsersControllerLoginData, void | ErrorResponse>({
        path: `/mobile/users/login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Check if a user has password
     *
     * @tags mobile-users
     * @name UsersControllerCheckHasPassword
     * @request POST:/mobile/users/has-password
     */
    usersControllerCheckHasPassword: (data: HasPasswordDto, params: RequestParams = {}) =>
      this.request<UsersControllerCheckHasPasswordData, void>({
        path: `/mobile/users/has-password`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Mark accepted_terms as true for certain user
     *
     * @tags mobile-users
     * @name UsersControllerAcceptTerms
     * @request POST:/mobile/users/accept-terms
     */
    usersControllerAcceptTerms: (data: HasPasswordDto, params: RequestParams = {}) =>
      this.request<UsersControllerAcceptTermsData, void>({
        path: `/mobile/users/accept-terms`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Reset users password
     *
     * @tags mobile-users
     * @name UsersControllerResetPassword
     * @request POST:/mobile/users/resetpassword
     * @secure
     */
    usersControllerResetPassword: (data: ResetPasswordDTO, params: RequestParams = {}) =>
      this.request<UsersControllerResetPasswordData, void>({
        path: `/mobile/users/resetpassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Reset users password
     *
     * @tags mobile-users
     * @name UsersControllerTestPushNotification
     * @request POST:/mobile/users/testpn
     * @secure
     */
    usersControllerTestPushNotification: (data: TestPushNotificationDTO, params: RequestParams = {}) =>
      this.request<UsersControllerTestPushNotificationData, void>({
        path: `/mobile/users/testpn`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Get user stats
     *
     * @tags mobile-users
     * @name UsersControllerGetUserStats
     * @request GET:/mobile/users/stats
     */
    usersControllerGetUserStats: (params: RequestParams = {}) =>
      this.request<UsersControllerGetUserStatsData, any>({
        path: `/mobile/users/stats`,
        method: "GET",
        ...params,
      }),
  };
  stats = {
    /**
     * @description Route for fetching client logo
     *
     * @tags dashboard-stats
     * @name DashBoardControllerGetPlatformOverview
     * @request GET:/stats/dashboard/overview
     */
    dashBoardControllerGetPlatformOverview: (params: RequestParams = {}) =>
      this.request<DashBoardControllerGetPlatformOverviewData, void>({
        path: `/stats/dashboard/overview`,
        method: "GET",
        ...params,
      }),
  };
  export = {
    /**
     * @description Public route for exporting specific content data
     *
     * @tags export
     * @name ExportControllerExportContent
     * @request GET:/export/content/{contentId}
     */
    exportControllerExportContent: (contentId: number, id: number, params: RequestParams = {}) =>
      this.request<ExportControllerExportContentData, any>({
        path: `/export/content/${contentId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for exporting specific content data
     *
     * @tags export
     * @name ExportControllerExportUsers
     * @request GET:/export/users
     */
    exportControllerExportUsers: (id: number, params: RequestParams = {}) =>
      this.request<ExportControllerExportUsersData, any>({
        path: `/export/users`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for exporting specific content data
     *
     * @tags export
     * @name ExportControllerExportUser
     * @request GET:/export/user/{userId}
     */
    exportControllerExportUser: (userId: number, id: number, params: RequestParams = {}) =>
      this.request<ExportControllerExportUserData, any>({
        path: `/export/user/${userId}`,
        method: "GET",
        ...params,
      }),

    /**
     * @description Public route for export client template
     *
     * @tags export
     * @name ExportControllerExportTemplate
     * @request GET:/export/template
     */
    exportControllerExportTemplate: (params: RequestParams = {}) =>
      this.request<ExportControllerExportTemplateData, any>({
        path: `/export/template`,
        method: "GET",
        ...params,
      }),
  };
  import = {
    /**
     * @description Public route for check import client template
     *
     * @tags import
     * @name ImportControllerCheckImportTemplate
     * @request POST:/import/template/check
     */
    importControllerCheckImportTemplate: (params: RequestParams = {}) =>
      this.request<ImportControllerCheckImportTemplateData, any>({
        path: `/import/template/check`,
        method: "POST",
        ...params,
      }),

    /**
     * @description Public route for importing client template
     *
     * @tags import
     * @name ImportControllerImportTemplate
     * @request POST:/import/template
     */
    importControllerImportTemplate: (data: UserImportChangesDto, params: RequestParams = {}) =>
      this.request<ImportControllerImportTemplateData, any>({
        path: `/import/template`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
